import React, { useEffect, useState } from "react"
import TextTransition, { presets } from "react-text-transition"
import { shuffle } from "lodash"

import Layout from "../components/layout"
import Seo from "../components/seo"

import KIcon from "../assets/images/k.svg"

import useInterval from "../utils/useInterval"

const IndexPage = () => {
  const [titlesIndex, setTitlesIndex] = useState(0)
  const [titles, setTitles] = useState([])

  useInterval(() => {
    setTitlesIndex(titlesIndex === titles.length - 1 ? 0 : titlesIndex + 1)
  }, 5000)

  useEffect(() => {
    setTitles(
      shuffle([
        "passionate and specialist developers",
        "technical directors and founders",
        "co-founders and partners",
        "inventors",
        "researchers and developers",
        "an idea and digital startup incubator",
        "digital nomads and technical experts",
        "contractors and freelancers",
        "a strategic development consultancy",
      ])
    )
  }, [])

  return (
    <Layout>
      <Seo
        title="Kavanagh Digital : A Specialist Tech Consultancy"
        description="We are passionate and specialist developers, technical directors and partners, founders and co-founders"
      />
      <div className="content">
        <h1 className="main-title">
          <span className="main-title-intro">
            We are <span>&mdash;</span>
          </span>
          <TextTransition spring={presets.gentle} direction="down">
            <span className="main-title-animated">{titles[titlesIndex]}</span>
          </TextTransition>
        </h1>
        <KIcon className="kicon" />
      </div>
    </Layout>
  )
}

export default IndexPage
